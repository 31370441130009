@import "../../assets/scss/variables.scss";

.product_card_background {
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
}

.product_img_container {
  height: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 30%;
  border-radius: 8px;

  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  padding: 5px;
}

.product_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.product_name_and_description_wrapper {
  height: 70px;
}

.product_name {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: $font-16;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.ordered_from {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: $font-12;
  color: $primaryColor;
  margin: 0;

  .bold {
    font-weight: $medium;
  }
}

.product_price {
  font-size: $font-16;
  font-weight: $medium;
  color: $accentColor;
  margin: 0;
}

.add_to_cart_button {
  width: 120px;
  height: 35px;
  background-color: $white;
  border: 1px solid $accentColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-14;
  font-weight: $medium;
  color: $accentColor;
}

.add_to_cart_button:hover {
  background-color: $accentColor;
  color: $white;
  transition: all 0.3s;
}

.quantity_count_wrapper {
  width: 90px;
  height: 30px;
  background: #eaf6ff;
  border: 1px solid $accentColor;
  color: $accentColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
}

.quantity_count_wrapper > div {
  width: 40px;
  height: 35px;
}

.subtract_svg_color,
.add_svg_color {
  fill: $accentColor;
}

.subtract_svg_wrapper {
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  cursor: pointer;
}

.subtract_svg_wrapper:hover {
  transition: all 0.3s;
  .subtract_svg_color {
    fill: darkblue;
  }
}

.add_svg_wrapper {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  cursor: pointer;
}

.add_svg_wrapper:hover {
  transition: all 0.3s;
  .add_svg_color {
    fill: darkblue;
  }
}

.quantity_count {
  font-size: $font-14;
  font-weight: $medium;
  color: $accentColor;
  text-align: center;
  margin: 0;
}

.remove_product_text {
  font-size: $font-12;
  color: $error;
  cursor: pointer;
  padding: 0 5px;
  margin: 0;
}

.remove_product_text:hover {
  font-weight: $medium;
}
