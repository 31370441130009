@import '../assets/scss/variables.scss';

// globalStyles.module.scss

$light-background-color: #ffffff;
$light-text-color: #000000;
$dark-background-color: #000000;
$dark-text-color: #ffffff;

:global {
  .light {
    background-color: $light-background-color;
    color: $light-text-color;
  }

  .dark {
    background-color: $dark-background-color;
    color: $dark-text-color;
  }
}

