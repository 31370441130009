@import "../../../../../assets/scss/variables.scss";

.radio_button_wrapper {
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.box_basis {
  flex-basis: 5%;
}

.name_basis {
  flex-basis: 95%;
}

.radio_button_wrapper:hover,
.edit_button:hover {
  background-color: rgba($accentColor, $alpha: 0.15);
  transition: background-color 0.3s;
  border-radius: 10px;
}

.radio_button_background {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $accentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
}

.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $accentColor;
  border: 0;
}

.non_active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $white;
  border: 0;
}

.edit_button {
  font-size: $font-12;
  color: $accentColor;
  text-align: left;
  margin: 0;
  background-color: transparent;
  border: 0;
}

.parent_radio {
  justify-content: space-between;
  flex-wrap: wrap;
}

.parent_edit_button {
  width: 30px !important
}