@import "../../../assets/scss/variables.scss";

.form_label {
  font-size: $font-14;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 5px;
}

.formControl {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.5;
  color: $primaryColor;
  background-color: transparent;
  border: 1px solid $secondaryColor;
  border-radius: 10px;
}

.error {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.5;
  color: $primaryColor;
  background-color: transparent;
  border: 1px solid $error;
  border-radius: 10px;
}

.error::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $error;
}

.formControl::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl:hover {
  border: 1px solid $accentColor;
}

.formControl:focus,
.error:focus {
  outline: none;
  border: 1px solid $accentColor;
}

.formControl:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.required:after {
  content:" *";
  color: red;
}

.password_input_container {
  display: flex !important;
}

.password_input_container input{
  border-radius: 10px 0px 0px 10px !important;
  border: none !important;
  padding: 0px !important;
}

.eye_button{
  width: 50px !important;
  border-radius: 0px 10px 10px 0px;
  border: none !important;
  padding: 0px !important;
}

.eye_button.error{
  // border-top: 1px solid #ff5959 !important;
  // border-bottom: 1px solid #ff5959 !important;
  // border-right: 1px solid #ff5959 !important;
  // padding: 7.5px 15px !important;
}