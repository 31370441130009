@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  font-family: 'Poppins';
}

body {
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Poppins';
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.text-error {
  color: red !important;
}

.text-amber {
  color: #ffbf00 !important;
}

body {
  background: #fff !important;
}

.MuiSnackbar-root {
  top: 90px !important;
  right: 25px !important;
}

.displayEllipsis {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.skiptranslate > span {
  display: none;
}
.goog-logo-link {
  display: none !important;
}

.goog-gt-tt {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
  display: block !important;
  top: 0;
  position: absolute;
  left: 0;
}
.goog-te-combo {
  border-radius: 8px;
  /* height: 28px; */
  position: absolute;
  border-color: white;
  top: -4px;
  left: 25px;
  padding: 5px;
  background: no-repeat;
  color: white;
  font-family: 'Poppins';
  min-width: 140px;
  max-width: 140px;
  margin-right: 10px !important;
  @media (max-width: 450px) {
    min-width: 150px;
    max-width: 150px;
    left: 0;
    top: -18px;
  }
}
.goog-te-gadget .goog-te-combo {
  margin: 2px 0 !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0px !important;
}
