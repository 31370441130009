@import "../../../assets/scss/variables.scss";

.checkbox_wrapper {
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.checkbox_wrapper:hover {
  .checkbox_background {
    background-color: rgba($accentColor, $alpha: 0.35);
  }
}

.box_basis {
  flex-basis: 20%;
}
.name_basis {
  flex-basis: 80%;
}

.checkbox_background {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: $white;
  border: 1px solid $accentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
}

.active {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: $accentColor;
  border: 1px solid $accentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
}
